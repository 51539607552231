<template>
  <v-container class="container-view" fluid>
    <div class="d-flex align-center justify-center flex-wrap text-center">
      <h1 class="text-h3 font-weight-black">Choisir sa structure</h1>
    </div>
    <v-slide-group show-arrows class="pt-12">
      <v-slide-group-item
        v-for="n in this.customers.length"
        :key="n"
        v-slot="{ isSelected }"
      >
        <v-sheet
          :elevation="4"
          :height="325"
          :width="325"
          class="d-flex align-center justify-center flex-wrap text-center my-3 mx-8 pl-4 pr-4"
        >
          <p class="text-body-2 font-weight-black">
            {{
              this.customers[n - 1].number + " - " + this.customers[n - 1].name
            }}
          </p>

          <div>
            <v-sheet
              class="d-flex"
              color="grey-lighten-3"
              height="225"
              width="225"
            >
            </v-sheet>
          </div>

          <v-btn
            :color="isSelected ? '#ffb500' : undefined"
            variant="text"
            @click="selectCustomer(this.customers[n - 1])"
          >
            Valider
          </v-btn>
        </v-sheet>
        <!-- <v-btn
          :color="isSelected ? 'primary' : undefined"
          class="ma-2"
          rounded
          @click="toggle"
        >
          Options {{ n }}
        </v-btn> -->
      </v-slide-group-item>
    </v-slide-group>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SelectCustomerView",
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.users.customers
    })
  },
  methods: {
    selectCustomer(customer) {
      this.$store.commit("users/setSelectedCustomer", customer);
      this.$router.push({ name: "home" });
    }
  }
};
</script>
