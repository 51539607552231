import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/userRoles${paginationString.text}`);
    } else {
      return axios.get("/api/userRoles");
    }
  }
};
