import strykerCommands from "../../api/strykerCommands";

export default {
  namespaced: true,
  state: {
    items: []
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        strykerCommands
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCustommerCommands({ commit }, id) {
      return new Promise((resolve, reject) => {
        strykerCommands
          .getCommands(id)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    }
  },
  getters: {}
};
