// import pagination from "../../api/pagination";

export default {
  namespaced: true,
  state: {
    currentPage: 1,
    hasNext: false,
    hasPrevious: false,
    totalCount: 1,
    totalPages: 1,
    pageSize: 10,
    currentFilter: "",
    currentOrderFilter: "",
    search: ""
  },
  getters: {},
  actions: {
    resetItems({ commit }) {
      commit("setResetItems");
    }
  },
  mutations: {
    setSearch(state, item) {
      state.search = item;
    },
    setCurrentFilter(state, item) {
      state.currentFilter = item;
    },
    setCurrentOrderFilter(state, item) {
      state.currentOrderFilter = item;
    },
    setItems(state, items) {
      state.currentPage = items.CurrentPage;
      state.hasNext = items.HasNext;
      state.hasPrevious = items.HasPrevious;
      state.pageSize = items.PageSize;
      state.totalCount = items.TotalCount;
      state.totalPages = items.TotalPages;
    },
    setResetItems(state) {
      state.currentPage = 1;
      state.hasNext = false;
      state.hasPrevious = false;
      state.totalCount = 1;
      state.totalPages = 1;
      state.pageSize = 10;
      state.currentFilter = "";
      state.currentOrderFilter = "";
      state.search = "";
    },
    setCurrentPage(state, item) {
      state.currentPage = item;
    },
    setHasNext(state, item) {
      state.hasNext = item;
    },
    setHasPrevious(state, item) {
      state.hasPrevious = item;
    },
    setTotalCount(state, item) {
      state.totalCount = item;
    },
    setTotalPages(state, item) {
      state.totalPages = item;
    },
    setPageSize(state, item) {
      state.pageSize = item;
    }
  }
};
