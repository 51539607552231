<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-tabs
        v-model="tab"
        @update:modelValue="tabUpdated"
        align-tabs="start"
        color="#FFB300"
        slider-color="white"
      >
        <v-tab elevation="6" value="clients">{{ $t("admin.customers") }}</v-tab>
        <v-tab elevation="6" value="users">{{ $t("admin.users") }}</v-tab>
        <v-tab elevation="6" value="products">{{ $t("products") }}</v-tab>
        <v-tab elevation="6" value="strykerCommands"
          >{{ $t("stryker-commands") }}
        </v-tab>
        <v-tab elevation="6" value="buyingGroups">
          {{ $t("buying-group") }}
        </v-tab>
        <!-- <v-tab elevation="6" value="franchises">
          {{ $t("admin.franchise") }}
        </v-tab>
        <v-tab elevation="6" value="franchiseGroups">
          {{ $t("admin.franchise-group") }}
        </v-tab> -->
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="clients">
          <client-admin></client-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="users">
          <user-admin></user-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="products">
          <product-admin></product-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="strykerCommands">
          <stryker-command-admin></stryker-command-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="buyingGroups">
          <buying-group-admin></buying-group-admin>
        </v-tabs-window-item>
        <!-- <v-tabs-window-item value="franchises">
          <franchise-admin></franchise-admin>
        </v-tabs-window-item>
        <v-tabs-window-item value="franchiseGroups">
          <franchise-group-admin></franchise-group-admin>
        </v-tabs-window-item> -->
      </v-tabs-window>
    </v-card>
  </v-container>
</template>

<script>
import ClientAdmin from "@/components/ClientAdmin.vue";
import UserAdmin from "@/components/UserAdmin.vue";
import ProductAdmin from "@/components/ProductAdmin.vue";
import StrykerCommandAdmin from "@/components/StrykerCommandAdmin.vue";
import BuyingGroupAdmin from "@/components/BuyingGroupAdmin.vue";
// import FranchiseAdmin from "@/components/FranchiseAdmin.vue";
// import FranchiseGroupAdmin from "@/components/FranchiseGroupAdmin.vue";

export default {
  name: "AdminView",
  components: {
    ClientAdmin,
    UserAdmin,
    ProductAdmin,
    StrykerCommandAdmin,
    BuyingGroupAdmin
    /* FranchiseAdmin,
    FranchiseGroupAdmin */
  },
  data() {
    return {
      tab: null
    };
  },
  methods: {
    tabUpdated(value) {
      this.$store.dispatch("paginations/resetItems");
      this.$store.commit("setOverlay", true);
      if (value == "strykerCommands") {
        this.$store.commit("strykerCommands/setItems", []);
      }
      this.$store.dispatch(`${value}/fetchAll`).then((response) => {
        if (value == "clients") {
          this.$store.dispatch("buyingGroups/fetchAll").then(() => {
            this.$store.commit("setOverlay", false);
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
          });
        } else if (
          value == "users" ||
          value == "franchises" ||
          value == "franchiseGroups"
        ) {
          this.$store.dispatch("fetchUserAdmin").then(() => {
            this.$store.commit("setOverlay", false);
            /* this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            ); */
          });
        } else {
          this.$store.commit("setOverlay", false);
          if (value != "buyingGroups") {
            this.$store.commit(
              "paginations/setItems",
              JSON.parse(response.headers.get("X-Pagination"))
            );
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.v-btn.v-theme--light.v-btn--density-default.v-btn--size-default.v-btn--variant-text.v-tab:not(
    .v-tab-item--selected
  ):not(.v-tab--selected) {
  background-color: #ffb300;
  color: white;
}
</style>
