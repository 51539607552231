<template>
  <v-snackbar :color="type" v-model="snackbar" :text="message" multi-line>
    <template v-slot:actions>
      <v-btn variant="text" @click="hideSnackbar"> {{ $t("close") }} </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarComponent",
  data() {
    return {
      //
    };
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar.model;
      },
      set(value) {
        if (!value) {
          this.$store.dispatch("hideSnackbar");
        }
      }
    },
    message() {
      return this.$store.state.snackbar.text;
    },
    type() {
      return this.$store.state.snackbar.color;
    }
  },
  methods: {
    hideSnackbar() {
      this.$store.dispatch("hideSnackbar");
    }
  }
};
</script>
