<template>
  <v-container class="container-view" fluid>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h5 font-weight-medium">{{ this.customer.name }}</div>
    </v-row>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h6 font-weight-medium">
        Status 3 - <span class="color-point"> 14 030 points</span>
      </div>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card elevation="6">
          <v-card-title class="title-background">
            {{ $t("my-last-commands") }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-model:page="page"
              show-expand
              :items="commandes"
              :headers="headers"
              :items-per-page="itemsPerPage"
              :no-data-text="$t('no-data')"
              item-value="orderNumber"
              v-model:expanded="expanded"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    <v-sheet elevation="9">
                      <v-data-table
                        show-expand
                        :items="item.orders"
                        :headers="headersOrders"
                        item-value="invoiceNumber"
                        density="compact"
                        hide-default-footer
                        hide-default-header
                      >
                        <!-- eslint-disable-next-line -->
                        <template
                          v-slot:item="{
                            item,
                            columns,
                            internalItem,
                            toggleExpand,
                            isExpanded
                          }"
                        >
                          <tr>
                            <!-- eslint-disable-next-line -->
                            <td v-for="column in columns">
                              <v-btn
                                v-if="column.key === 'data-table-expand'"
                                :icon="
                                  isExpanded(internalItem)
                                    ? mdiArrowUpDropCircleOutline
                                    : mdiArrowDownDropCircleOutline
                                "
                                variant="plain"
                                @click="toggleExpand(internalItem)"
                              ></v-btn>
                              <span v-else>{{
                                $t("invoice-number") + " : " + item[column.key]
                              }}</span>
                            </td>
                          </tr>
                        </template>
                        <template v-slot:expanded-row="{ columns, item }">
                          <tr>
                            <td :colspan="columns.length">
                              <v-sheet elevation="9" class="mt-3 mb-3">
                                <v-table>
                                  <thead>
                                    <tr class="grey lighten-4">
                                      <th>{{ $t("reference") }}</th>
                                      <th>{{ $t("description") }}</th>
                                      <th>{{ $t("price") }}</th>
                                      <th>{{ $t("quantity") }}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(product, index) in item.products"
                                      :key="index"
                                      class="grey lighten-4"
                                    >
                                      <td>
                                        {{ product.reference }}
                                      </td>
                                      <td>
                                        {{ product.description }}
                                      </td>
                                      <td>
                                        {{ product.salesAmount }}
                                      </td>
                                      <td>
                                        {{ product.quantity }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </v-table>
                              </v-sheet>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-sheet>
                  </td>
                </tr>
              </template>
              <template v-slot:bottom>
                <div class="text-center pt-2">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0">
        <div id="chart">
          <apexchart
            class="apex-chart"
            type="donut"
            width="500"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  mdiArrowUpDropCircleOutline,
  mdiArrowDownDropCircleOutline
} from "@mdi/js";

export default {
  name: "StrykerCommands",
  data() {
    return {
      mdiArrowDownDropCircleOutline,
      mdiArrowUpDropCircleOutline,
      page: 1,
      expanded: [],
      itemsPerPage: 10,
      headers: [
        {
          title: this.$t("command-date"),
          align: "start",
          sortable: false,
          key: "date"
        },
        {
          title: this.$t("order-number"),
          align: "start",
          key: "orderNumber"
        },
        { title: this.$t("total"), align: "start", key: "total" }
      ],
      headersOrders: [
        {
          title: this.$t("invoice-number"),
          align: "start",
          key: "invoiceNumber"
        }
      ],
      series: [
        540150, 300000, 150000, 50000, 10000, 10000, 10000, 9000, 8000, 7000,
        6000, 5000
      ],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: [
          "Knees",
          "HPS",
          "Upper extremities",
          "OI",
          "Foot and ankle",
          "Trauma",
          "ST",
          "Neurosurgical",
          "IVS",
          "CMP",
          "Sports medicine",
          "ENT"
        ],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 500
        }
      }
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer
    }),
    pageCount() {
      return Math.ceil(this.commandes.length / this.itemsPerPage);
    },
    commandes: {
      get() {
        return this.$store.state.strykerCommands.items;
      }
    }
  },
  methods: {}
};
</script>

<style scoped>
.apex-chart {
  padding-top: 50px;
}
</style>
