<template>
  <v-container class="container-view" fluid>
    <v-row>
      <v-col
        cols="1"
        class="d-flex align-center justify-center flex-wrap text-center px-0 py-0"
      >
        <!-- eslint-disable -->
        <v-btn
          v-if="this.breadcrumbsItem[1] !== undefined"
          variant="text"
          class="px-0 py-0"
          @click="
            handleBreadcrumbClick(
              breadcrumbsItem[breadcrumbsItem.length - 1],
              'notobject'
            )
          "
        >
          < {{ $t("back") }}
        </v-btn>
      </v-col>
      <v-col cols="8" class="d-flex align-center justify-start px-0 py-0">
        <v-breadcrumbs :items="breadcrumbsItem" class="px-0 py-0">
          <template v-slot:divider>
            <v-icon class="px-0 py-0" :icon="mdiChevronRight"></v-icon>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:title="{ item }">
            <span
              @click="handleBreadcrumbClick(item, 'object')"
              class="text-h6 font-weight-bold"
            >
              {{ item.title }}
            </span>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3" class="px-0 py-0">
        <v-text-field
          :append-inner-icon="mdiMagnify"
          :label="this.$t('search-product')"
          v-model="search"
          variant="outlined"
        ></v-text-field>
      </v-col>
    </v-row>

    <op-selection
      v-if="breadcrumbsItem[1] === undefined && search === ''"
      @selectConfiguration="selectConfiguration"
    ></op-selection>

    <v-card elevation="6" v-else-if="selectedProduct == null">
      <v-card-title v-if="search !== ''" class="title-background">
        {{ $t("products") }}
      </v-card-title>
      <v-card-title v-else class="title-background">
        {{ $t("products") }} {{ breadcrumbsItem[1] }} {{ $t("corresponding") }}
      </v-card-title>
      <v-card-text>
        <div>
          <v-data-table
            :items="products"
            :headers="headers"
            :search="search"
            @click:row="selectProduct"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.image="{ item }">
              <v-img
                :src="require(`@/assets/${item.image}`)"
                width="50"
                height="50"
                contain
              ></v-img>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn variant="text" :icon="mdiCartMinus"></v-btn>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>

    <v-row v-else-if="selectedProduct != null">
      <v-col cols="6" class="px-8">
        <v-sheet elevation="9">
          <v-carousel hide-delimiter-background>
            <v-carousel-item
              v-for="(item, i) in this.selectedProduct.image"
              :key="i"
              :src="require(`@/assets/${item}`)"
              cover
            >
            </v-carousel-item>
          </v-carousel>
        </v-sheet>
      </v-col>
      <v-col cols="6" class="px-5">
        <div>
          <h2 class="text-h6 font-italic">
            {{ $t("product-reference") }} : {{ this.selectedProduct.code }}
          </h2>
        </div>
        <div>
          <h2 class="text-h5 font-weight-black text-color pb-3">
            {{ this.selectedProduct.description }}
          </h2>
        </div>
        <div class="pb-2">
          <h2 class="text-h6 font-weight-regular">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
            ullamcorper suscipit lobortis nisl ut aliquip.
          </h2>
        </div>
        <v-divider class="pb-3 border-opacity-50"></v-divider>
        <div class="pb-5">
          <v-card elevation="9">
            <v-card-title class="title-background">
              <h2 class="text-h6 font-weight-bold">{{ $t("more-details") }}</h2>
            </v-card-title>

            <v-card-text>
              <v-table class="px-5 py-5">
                <tbody>
                  <tr>
                    <td class="background-gray text-h7">
                      {{ $t("manufacturer") }}
                    </td>
                    <td class="text-h7">Loremipsum</td>
                  </tr>
                  <tr>
                    <td class="background-gray text-h7">{{ $t("brand") }}</td>
                    <td class="text-h7">Loremipsum</td>
                  </tr>
                  <tr>
                    <td class="background-gray text-h7">
                      {{ $t("reference") }}
                    </td>
                    <td class="text-h7">
                      {{ this.selectedProduct.code }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
          </v-card>
        </div>
        <v-divider class="border-opacity-50"></v-divider>
        <div class="pt-5">
          <v-col cols="6">
            <v-text-field
              :label="this.$t('quantity')"
              variant="outlined"
              v-model="quantity"
              type="number"
              :rules="[rules.required, rules.numeric]"
            ></v-text-field>
          </v-col>
        </div>
        <div>
          <h2 class="text-h4 font-weight-bold text-color pb-5">
            {{
              Math.ceil(
                parseFloat(this.selectedProduct.price.replace(",", "."))
              ) +
              "pts / " +
              this.selectedProduct.price +
              "€"
            }}
          </h2>
        </div>
        <v-divider class="border-opacity-50"></v-divider>
        <div class="pt-4">
          <v-btn
            class="color-button"
            @click="addProductToCart"
            @keydown.enter.prevent="addProductToCart"
            :loading="loading"
            variant="elevated"
          >
            {{ $t("add-to-cart") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiMinus,
  mdiMagnify,
  mdiCartMinus
} from "@mdi/js";
import OpSelection from "@/components/OpSelection.vue";

export default {
  name: "PointView",
  components: {
    OpSelection
  },
  data() {
    return {
      mdiChevronRight,
      mdiChevronLeft,
      mdiMinus,
      search: "",
      mdiMagnify,
      mdiCartMinus,
      loading: false,
      breadcrumbsItem: [this.$t("menu.use-points")],
      selectedProduct: null,
      headers: [
        {
          title: this.$t("image"),
          align: "start",
          sortable: false,
          key: "image"
        },
        { title: this.$t("reference"), align: "start", key: "code" },
        { title: this.$t("description"), align: "start", key: "description" },
        { title: this.$t("price"), align: "start", key: "price" },
        { title: this.$t("vat"), align: "start", key: "tva" },
        { title: this.$t("brand"), align: "start", key: "brand" },
        { title: this.$t("actions"), align: "start", key: "actions" }
      ],
      products: [
        {
          code: "0001011000",
          description: "10-32 X 1/2 10/04",
          price: "0,57",
          tva: "20",
          brand: "MEDICAL REPAIR",
          image: ["svg/package.svg"]
        },
        {
          code: "0001097000",
          description: "SCREW,5/16-24 X 7/8 ,TAP.HSH",
          price: "1,12",
          tva: "20",
          brand: "",
          image: ["svg/package.svg"]
        },
        {
          code: "3910-500-104",
          description: "DRILL KNOTILUS",
          price: "509,57",
          tva: "20",
          brand: "ANCRES DIVERS",
          image: ["svg/package.svg"]
        },
        {
          code: "3910-400-107",
          description: "TWINLOOP FLEXTM 0O GUIDE",
          price: "569,7",
          tva: "20",
          brand: "ANCRES INSTABILITE",
          image: ["svg/package.svg"]
        },
        {
          code: "5532G711",
          description: "TRIATHLON - INSERT TIBIAL X3 FIXE PS T7 L 11MM",
          price: "319,56",
          tva: "5,5",
          brand: "TRIATHLON",
          image: ["svg/package.svg"]
        },
        {
          code: "60016611",
          description: "INVISION PEG TALAR PLATE CASE AND TRAY 1",
          price: "1082,08",
          tva: "20",
          brand: "",
          image: ["svg/package.svg"]
        },
        {
          code: "60016617",
          description: "INVISION EXPANSION TRAY",
          price: "1493,83",
          tva: "20",
          brand: "",
          image: ["svg/package.svg"]
        },
        {
          code: "1267458BE",
          description: "Boîte de stérélisation urologie - gynécologie",
          price: "500",
          tva: "20",
          brand: "",
          image: ["product.png"]
        },
        {
          code: "6100031083",
          description: "TUBE BASE ATTACHMENT",
          price: "43,71",
          tva: "20",
          brand: "EMS SERVICE",
          image: ["svg/package.svg"]
        },
        {
          code: "6278-8-215",
          description: "MED REV 15MM CONICAL REAMER",
          price: "555,13",
          tva: "20",
          brand: "Z MAT EUROPE",
          image: ["svg/package.svg"]
        }
      ],
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        numeric: (value) => !isNaN(value) || "Ce champ doit être un nombre."
      },
      quantity: 0
    };
  },
  methods: {
    selectConfiguration(configuration) {
      this.breadcrumbsItem[1] = configuration;
    },
    selectProduct(e, { item }) {
      this.breadcrumbsItem.push(item.code);
      this.selectedProduct = item;
    },
    addProductToCart() {
      console.log("Add product to cart");
    },
    handleBreadcrumbClick(item, type) {
      const itemTitle = type === "object" ? item.title : item;
      if (
        itemTitle === this.$t("menu.use-points") &&
        this.selectedProduct !== null
      ) {
        this.selectedProduct = null;
        this.breadcrumbsItem.pop();
        this.breadcrumbsItem.pop();
        // this.breadcrumbsItem[1] = undefined;
      } else if (itemTitle === this.$t("menu.use-points")) {
        this.breadcrumbsItem.pop();
        this.selectedProduct = null;
      } else if (
        (itemTitle === "per-op" ||
          itemTitle === "post-op" ||
          itemTitle === "pre-op") &&
        this.selectedProduct !== null
      ) {
        this.selectedProduct = null;
        this.breadcrumbsItem.pop();
      } else if (
        itemTitle === "per-op" ||
        itemTitle === "post-op" ||
        itemTitle === "pre-op"
      ) {
        this.breadcrumbsItem.pop();
      } else {
        this.breadcrumbsItem.pop();
        this.selectedProduct = null;
      }
    }
  }
};
</script>

<style scoped>
.background-gray {
  background-color: #d6d7d7;
}
.text-color {
  color: #feb100;
}

.title-background {
  background-color: #535756;
  color: white;
}

.color-button {
  background: -webkit-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions de Chrome et Safari */
  background: -moz-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour Firefox */
  background: -o-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions d'Opera */
  background: linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les navigateurs modernes */
}
</style>
