<template>
  <login-component></login-component>
</template>

<script>
import LoginComponent from "@/components/LoginComponent.vue";

export default {
  name: "LoginView",
  components: {
    LoginComponent
  },
  data() {
    return {
      //
    };
  }
};
</script>
