<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiPackageVariantClosed" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('reference') + ' *'"
                v-model="editedItem.reference"
                :rules="referenceRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.lppr')"
                v-model="editedItem.lppr"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                :label="$t('description') + ' *'"
                v-model="editedItem.description"
                :rules="descriptionRules"
                required
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                :label="$t('description-local') + ' *'"
                v-model="editedItem.descriptionLocal"
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.price') + ' *'"
                v-model="editedItem.price"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('vat')"
                v-model="editedItem.tva"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.currency') + ' *'"
                v-model="editedItem.currency"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.franchise')"
                v-model="editedItem.fK_Franchise"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.franchise-group')"
                v-model="editedItem.fK_FranchiseGroup"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.sub-franchise')"
                v-model="editedItem.fK_SubFranchise"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-brand')"
                v-model="editedItem.fK_GimBrand"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-item-type')"
                v-model="editedItem.fK_GimItemType"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-item-type')"
                v-model="editedItem.fK_GimProductLine"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-sub-brand')"
                v-model="editedItem.fK_GimSubBrand"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.sapg')"
                v-model="editedItem.fK_SAPG"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.target-product-group')"
                v-model="editedItem.fK_TargetProductGroup"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-id') + ' *'"
                v-model="editedItem.gimID"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-pack-content') + ' *'"
                v-model="editedItem.gimPackContent"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-sterility-flag') + ' *'"
                v-model="editedItem.gimSterilityFlag"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gim-uom') + ' *'"
                v-model="editedItem.gimUOM"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.gtin-code') + ' *'"
                v-model="editedItem.gtinCode"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.primary-uom') + ' *'"
                v-model="editedItem.primaryUOM"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('admin.updated-at')"
                disabled
                :model-value="
                  editedItem.updatedAt != null
                    ? formatedDate(editedItem.updatedAt)
                    : ''
                "
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mdiPackageVariantClosed, mdiPencil } from "@mdi/js";

export default {
  name: "AddEditProduct",
  props: ["modelDialog"],
  data() {
    return {
      mdiPackageVariantClosed,
      mdiPencil,
      referenceRules: [
        (value) => {
          if (value) return true;
          return "Reference is mandatory";
        }
      ],
      descriptionRules: [
        (value) => {
          if (value) return true;
          return "Reference is mandatory";
        }
      ]
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.products.editedId
    }),
    editedItem: {
      get() {
        return this.$store.state.products.editedItem;
      },
      set(value) {
        this.$store.commit("products/setEditedItem", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-product")
        : this.$t("admin.edit-product");
    }
  },
  methods: {
    formatedDate(inputDate) {
      // Convertir en objet Date
      const date = new Date(inputDate);

      // Extraire le jour, le mois et l'année
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
      const year = date.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedId === -1) {
          this.$store.dispatch("products/saveEditedItem");
          this.close();
        } else {
          this.$store.dispatch("products/updateEditedItem");
          this.close();
        }
      }
    }
  }
};
</script>
