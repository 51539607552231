<template>
  <v-container class="pl-0 pr-0" fluid>
    <v-row>
      <v-col cols="8">
        <first-carousel-item :isInCarousel="false"></first-carousel-item>
      </v-col>
      <v-col cols="4">
        <second-carousel-item :isInCarousel="false"></second-carousel-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FirstCarouselItem from "./FirstCarouselItem.vue";
import SecondCarouselItem from "./SecondCarouselItem.vue";
export default {
  name: "DataComponent",
  components: {
    FirstCarouselItem,
    SecondCarouselItem
  },
  data() {
    return {
      color: "indigo",
      slide: "First"
    };
  }
};
</script>

<style scoped></style>
