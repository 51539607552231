<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiAccount" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.customer-number') + ' *'"
                v-model="editedItem.number"
                :rules="numberRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('admin.customer-name') + ' *'"
                v-model="editedItem.name"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('email')"
                v-model="editedItem.email"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('phone')"
                v-model="editedItem.phone"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-select
                v-model="editedItem.fK_BuyingGroup"
                :items="buyingGroups"
                item-title="name"
                item-value="id"
              ></v-select>
              <!-- <v-text-field
                :label="$t('buying-group')"
                v-model="editedItem.fK_BuyingGroup"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('statut')"
                v-model="editedItem.fK_Status"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('admin.updated-at')"
                disabled
                :model-value="
                  editedItem.updatedAt != null
                    ? formatedDate(editedItem.updatedAt)
                    : ''
                "
                required
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('admin.updated-by')"
              disabled
              v-model="editedItem.updatedBy"
              required
            ></v-text-field>
          </v-col> -->
          </v-row>
          <small class="text-caption text-medium-emphasis">{{
            $t("require-fields")
          }}</small>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiPencil, mdiAccount } from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "AddEditCustomer",
  props: ["modelDialog"],
  data() {
    return {
      mdiPencil,
      mdiAccount,
      numberRules: [
        (value) => {
          if (value) return true;
          return "Customer number is mandatory";
        }
      ],
      nameRules: [
        (value) => {
          if (value) return true;
          return "Customer name is mandatory";
        }
      ]
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.clients.editedId,
      buyingGroups: (state) => state.buyingGroups.items
    }),
    editedItem: {
      get() {
        return this.$store.state.clients.editedItem;
      },
      set(value) {
        this.$store.commit("clients/setEditedItem", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-customer")
        : this.$t("admin.edit-customer");
    }
  },
  methods: {
    formatedDate(inputDate) {
      // Convertir en objet Date
      const date = new Date(inputDate);

      // Extraire le jour, le mois et l'année
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
      const year = date.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.validate();
      if (valid) {
        if (this.editedId === -1) {
          this.editedItem.fK_Status = 1;
          this.$store.dispatch("clients/saveEditedItem");
          this.close();
        } else {
          this.$store.dispatch("clients/updateEditedItem");
          this.close();
        }
      }
    }
  }
};
</script>
