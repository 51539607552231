import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/strykerCommands${paginationString.text}`);
    } else {
      return axios.get("/api/strykerCommands");
    }
  },
  getCommands(id) {
    return axios.get(`/api/strykerCommands/by-customer/${id}`);
  }
};
