<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="customers"
      :items-length="totalItems"
      :search="search"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog"
            dark
          >
            Nouveau client
          </v-btn>
          <import-file-dialog :table="'customers'"></import-file-dialog>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.statut="{ item }">
        <v-chip color="#ffb300" dark>
          {{ item.statut }}
        </v-chip>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon class="me-2" size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
        <v-tooltip location="top" text="Afficher le compte client">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              class="me-2"
              size="large"
              @click="showCustomer(item)"
            >
              {{ mdiAccountArrowRight }}
            </v-icon>
          </template>
        </v-tooltip>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'clients'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-customer
      :modelDialog="customerDialog"
      @close="customerDialog = false"
    ></add-edit-customer>
  </v-container>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiMagnify,
  mdiAccountPlus,
  mdiAccountArrowRight
} from "@mdi/js";
import AddEditCustomer from "./admin/AddEditCustomer.vue";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import ImportFileDialog from "./ImportFileDialog.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "ClientAdmin",
  components: {
    ImportFileDialog,
    PaginationDynamic,
    AddEditCustomer
  },
  mixins: [paginate],
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiAccountPlus,
      mdiAccountArrowRight,
      customerDialog: false,
      headers: [
        {
          title: this.$t("admin.customer-number"),
          align: "start",
          sortable: false,
          key: "number"
        },
        { title: this.$t("admin.customer-name"), key: "name" },
        { title: this.$t("city"), key: "city" },
        { title: this.$t("address"), key: "address" },
        { title: this.$t("statut"), key: "statut" },
        { title: this.$t("points"), key: "points" },
        { title: this.$t("actions"), key: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      customers: (state) => state.clients.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount,
      editedId: (state) => state.clients.editedId
    }),
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },
  methods: {
    showCustomer(item) {
      var customer = {
        id: item.id,
        logo: item.logo,
        name: item.name,
        status: item.status
      };
      this.$store.commit("users/setSelectedCustomer", customer);
      this.$router.push({ name: "home" });
    },
    toSort(item) {
      this.sortBy(item, "clients");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "clients");
    },
    openDialog() {
      this.$store.commit("clients/setEditedId", -1);
      this.$store.commit("clients/setEditedItem", Object.assign({}, {}));
      this.customerDialog = true;
    },
    editItem(item) {
      this.$store.commit("clients/setEditedId", item.id);
      this.$store.commit("clients/setEditedItem", Object.assign({}, item));
      this.customerDialog = true;
    },

    deleteItem(item) {
      if (confirm(this.$t("alert.delete.customer"))) {
        this.$store
          .dispatch("clients/deleteItem", item.id)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.customer-delete"),
              color: "success"
            });
            this.toSearchBack(this.search, 500, "customers");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.customer-delete"),
              color: "error"
            });
          });
      }
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>
