import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/buyingGroups${paginationString.text}`);
    } else {
      return axios.get("/api/buyingGroups");
    }
  },
  store(buyingGroup) {
    return axios.post("/api/buyingGroups", buyingGroup);
  },
  delete(id) {
    return axios.delete(`/api/buyingGroups/${id}`);
  },
  update(id, buyingGroup) {
    return axios.put(`/api/buyingGroups/${id}`, buyingGroup);
  }
};
