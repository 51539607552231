<template>
  <v-container fluid class="container-view">
    <div>
      <!-- todo add search field  and timeline -->
    </div>
    <v-row>
      <v-col cols="9">
        <v-card elevation="9">
          <v-card-title class="title-background">
            <h2 class="text-h6 font-weight-bold">{{ $t("my-cart") }}</h2>
          </v-card-title>

          <v-card-text>
            <!-- @/assets/product.png -->
            <v-data-table
              :headers="headers"
              :items="cart"
              density="compact"
              hide-default-footer
              item-key="name"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.image="{ item }">
                <v-img
                  :src="require(`@/assets/${item.image}`)"
                  width="50"
                  height="50"
                  contain
                ></v-img>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.total="{ item }">
                {{ item.point * item.quantity }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  v-model="item.quantity"
                  solo
                  dense
                  class="mt-2 mb-2"
                  type="number"
                  hide-details
                ></v-text-field>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon size="small" @click="deleteItem(item)">
                  {{ mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card elevation="9">
          <v-card-title class="title-background">
            <h2 class="text-h6 font-weight-bold">{{ $t("command-resume") }}</h2>
          </v-card-title>
          <v-card-text>
            <v-table class="px-2 py-2 command-table">
              <tbody>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("total-article") }} :
                  </td>
                  <td class="text-h7 command-table">
                    {{ this.totalProduct() }} points
                  </td>
                </tr>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("delivery") }} :
                  </td>
                  <td class="text-h7 command-table">
                    {{ this.delivery }} points
                  </td>
                </tr>
                <tr>
                  <td class="background-gray text-h7 command-table">
                    {{ $t("total-amount") }} :
                  </td>
                  <td class="text-h7 command-table">
                    {{ this.totalProduct() + this.delivery }} points
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-btn
              class="mt-5 color-button text-white"
              variant="elevated"
              block
              @click="checkout()"
            >
              {{ $t("proceed-to-checkout") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiDelete } from "@mdi/js";
export default {
  name: "CartView",
  data() {
    return {
      mdiDelete,
      delivery: 100,
      cart: [
        {
          image: ["product.png"],
          reference: "REF-001",
          description: "Description du produit",
          point: "120",
          quantity: 1
        },
        {
          image: ["product.png"],
          reference: "REF-002",
          description: "Description du produit 2",
          point: "220",
          quantity: 1
        }
      ],
      headers: [
        { title: "Produit", align: "start", sortable: false, key: "image" },
        {
          title: "Référence",
          align: "start",
          sortable: true,
          key: "reference"
        },
        {
          title: "Description",
          align: "start",
          sortable: true,
          key: "description"
        },
        {
          title: "Point",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "point"
        },
        {
          title: "Quantité",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "quantity"
        },
        {
          title: "Total",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "total"
        },
        { title: "Actions", align: "end", sortable: true, key: "actions" }
      ]
    };
  },
  methods: {
    checkout() {
      console.log("checkout");
    },
    deleteItem() {
      console.log("delete item");
    },
    totalProduct() {
      var totalP = 0;
      this.cart.forEach((item) => {
        totalP += item.point * item.quantity;
      });
      return totalP;
    }
  },
  created() {}
};
</script>

<style>
.command-table {
  border-bottom: none !important;
}
</style>
