import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import Contrat from "@/views/ContactView.vue";
import StrykerCommands from "@/views/StrykerCommands.vue";
import StrykerOneCommands from "@/views/StrykerOneCommands.vue";
import ContratView from "@/views/ContratView.vue";
import AdminView from "@/views/AdminView.vue";
import OneStrykerService from "@/views/OneStrykerService.vue";
import CartView from "@/views/CartView.vue";
import PointView from "@/views/PointView.vue";
import SelectCustomerView from "@/views/SelectCustomerView.vue";

import store from "../store";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/contact",
    name: "contact",
    component: Contrat
  },
  {
    path: "/stryker-commands",
    name: "stryker-commands",
    component: StrykerCommands
  },
  {
    path: "/stryker-one-commands",
    name: "stryker-one-commands",
    component: StrykerOneCommands
  },
  {
    path: "/contrat",
    name: "contrat",
    component: ContratView
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView
  },
  {
    path: "/service-one-stryker",
    name: "service-one-stryker",
    component: OneStrykerService
  },
  {
    path: "/points",
    name: "points",
    component: PointView
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView
  },
  {
    path: "/select-customer",
    name: "select-customer",
    component: SelectCustomerView
  },
  {
    /* path: '/about',
    name: 'about', */
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}

router.beforeEach((to, from, next) => {
  // var isAdmin = store.state.users.user.userRole === "ADMIN";
  var custStrykerOne = localStorage.getItem("cust_stryker_one");
  if (custStrykerOne) {
    store.commit("users/setSelectedCustomer", JSON.parse(custStrykerOne));
    if (
      isEmpty(store.state.users.customers) &&
      store.state.users.user?.userRole != "ADMIN"
    ) {
      var tokenCustomer = localStorage.getItem("authToken");
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + tokenCustomer;

      store.dispatch("users/getUserInfosWithCustomers");
    }
  }
  if (isEmpty(store.state.auth.token)) {
    var token = localStorage.getItem("authToken");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      store.commit("auth/setToken", token);
    }
  }
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = !isEmpty(store.state.auth.token);
  const customerIdPages = [
    "/",
    "/stryker-one-commands",
    "/stryker-commands",
    "/contrat",
    "/service-one-stryker",
    "/points"
  ];
  const requiredCustomerId = customerIdPages.includes(to.path);
  // Redirection si l'utilisateur est déjà connecté et essaie d'accéder à /login
  if (loggedIn && to.path === "/login") {
    return next("/"); // Redirection vers la page d'accueil
  }

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  if (loggedIn && requiredCustomerId && !custStrykerOne) {
    var tokenRouteCustomer = localStorage.getItem("authToken");
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + tokenRouteCustomer;

    if (store.state.users.user?.userRole != "ADMIN") {
      store.dispatch("users/getUserInfosWithCustomers");
      return next("/select-customer");
    } else {
      return next("/admin");
    }
  }

  if (!router.getRoutes().some((route) => route.path === to.path)) {
    return next("/");
  }
  return next();
});

export default router;
