<template>
  <v-container>
    <v-row class="pt-5">
      <v-col cols="5" :class="{ 'total-points-sheet': isInCarousel }">
        <div v-if="!isInCarousel" class="div-sheet-points">
          <v-sheet
            elevation="8"
            class="d-flex align-center justify-center flex-wrap text-center"
            height="200"
            width="250"
          >
            <div>
              <h2 class="text-h2 font-weight-black text-color">14.03K</h2>

              <div class="text-body-2 mt-2">
                {{ this.$t("total-pts-year", new Date().getFullYear()) }}
              </div>
            </div>
          </v-sheet>
        </div>
        <div v-else>
          <v-sheet
            elevation="8"
            class="d-flex align-center justify-center flex-wrap text-center"
            height="200"
            width="250"
          >
            <div>
              <h2 class="text-h2 font-weight-black text-color">14.03K</h2>

              <div class="text-body-2 mt-2">
                {{ this.$t("total-pts-year", new Date().getFullYear()) }}
              </div>
            </div>
          </v-sheet>
        </div>
      </v-col>

      <v-col cols="7">
        <div>
          <v-sheet
            class="first-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col cols="2" class="pr-0">
                <h2 class="text-h6 font-weight-regular">
                  {{ new Date().getFullYear() - 1 }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                class="border-opacity-100 divider-height"
                vertical
              ></v-divider>
              <v-col>
                <h2 class="text-h5 text-center font-weight-bold">
                  Statut 3 - 1,66M€ ventes {{ new Date().getFullYear() - 1 }}
                </h2>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <div>
          <v-sheet
            class="second-sheet-carousel-command d-flex align-center"
            elevation="8"
            height="90"
            width="99%"
          >
            <v-row>
              <v-col
                cols="2"
                class="pr-0 d-flex align-center justify-center flex-wrap text-center"
              >
                <h2 class="text-h6 font-weight-regular">
                  {{ new Date().getFullYear() }}
                </h2>
              </v-col>
              <v-divider
                :thickness="2"
                length="60"
                class="border-opacity-100 divider-center"
                vertical
              ></v-divider>
              <v-col>
                <h2 class="text-h6 text-center font-weight-bold">
                  Statut 3 - 1.89M€ ventes {{ new Date().getFullYear() }}
                </h2>
                <h5>{{ this.$t("projection") }}</h5>
                <v-row
                  class="d-flex align-center justify-center flex-wrap text-center"
                >
                  <v-col cols="11">
                    <v-slider
                      v-model="statusProgress2"
                      :min="0"
                      :max="3"
                      :ticks="tickLabels2"
                      color="#ffb500"
                      show-ticks="always"
                      tick-size="4"
                    >
                    </v-slider>
                    <!-- <v-slider
                      v-model="statusProgress"
                      :min="400000"
                      :max="2100000"
                      thumb-label="always"
                      :ticks="tickLabels"
                      color="#ffb500"
                      show-ticks="always"
                      tick-size="4"
                    >
                      <template v-slot:thumb-label="{ modelValue }">
                        {{ (modelValue / 1000000).toFixed(2) + "M" }}
                      </template>
                      <template v-slot:tick-label="{ tick }">
                        <p class="text-caption font-weight-bold">
                          {{ tick.label }}
                        </p>
                      </template>
                    </v-slider> -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">141</h2>

            <div class="text-caption mt-2">
              {{ this.$t("points") }} {{ new Date().getFullYear() }}
              {{ this.$t("capital") }}
            </div>
          </div>
        </div>
      </v-col>

      <v-divider :thickness="1" class="border-opacity-50" vertical></v-divider>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">4,26K</h2>

            <div class="text-caption mt-2">
              {{ this.$t("points") }} {{ new Date().getFullYear() }}
              {{ this.$t("consommable") }}
            </div>
          </div>
        </div>
      </v-col>

      <v-divider :thickness="1" class="border-opacity-50" vertical></v-divider>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">9K</h2>

            <div class="text-caption mt-2">
              {{ this.$t("points") }} {{ new Date().getFullYear() }}
              {{ this.$t("implant") }}
            </div>
          </div>
        </div>
      </v-col>

      <v-divider :thickness="1" class="border-opacity-50" vertical></v-divider>
      <v-col cols="3">
        <div class="d-flex align-center justify-center flex-wrap text-center">
          <div>
            <h2 class="text-h4 font-weight-black text-color">268</h2>

            <div class="text-caption mt-2">
              {{ this.$t("points") }} {{ new Date().getFullYear() }}
              {{ this.$t("services") }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FirstCarouselItem",
  props: {
    isInCarousel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      color: "indigo",
      slide: "First",
      statusProgress: 1890000,
      tickLabels: {
        400000: "Statut 1",
        700000: "Statut 2",
        1200000: "Statut 3",
        2100000: "Statut 4"
      },
      statusProgress2: 2.8,
      tickLabels2: {
        0: "Statut 1",
        1: "Statut 2",
        2: "Statut 3",
        3: "Statut 4"
      }
    };
  }
};
</script>

<style scoped>
.div-sheet-points {
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-text-statut {
  text-align: center;
}

.first-sheet-carousel-command {
  margin-bottom: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.second-sheet-carousel-command {
  margin-top: 20px;
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
}

.total-points-sheet {
  padding-left: 50px;
}

.text-color {
  color: #feb100;
}

.divider-center {
  align-self: center;
}
</style>
